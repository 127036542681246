import Vue from 'vue';
import { getGlobal } from '../store';
import { BlokHeadingBox, BlokHeadingSection, SeoHeader, SeoTags, StoryblokResponse, StoryblokStory } from '~/types';

declare module 'vue/types/vue' {
  interface Vue {
    $seoHeader(seo: SeoHeader | null, storyName: string): SeoTags,
    $initStorybridge(storybridge: any, story: StoryblokStory): void,
    $readableDate(datestring: string, locale: string): string,
    $minifiedDate(datestring: string): string
  }
}

Vue.prototype.$seoHeader = (seo: SeoHeader | null, storyName: string): SeoTags => {
  const defaultSEOTitle: string = process.env.NUXT_APP_META_SITENAME || '';
  const defaultSEODescription: string = process.env.NUXT_APP_META_DESCRIPTION || '';
  const defaultURL: string = process.env.NUXT_APP_BASE_URL || '';
  const parseTitle = (text: string|BlokHeadingBox[]|BlokHeadingSection[]): string => {
    if (typeof text === 'object') {
      if (!text.length) { return defaultSEOTitle; }
      return text[0].title?.replace(/(<|&lt;)br\s*\/*(>|&gt;)/g, ' ') || '';
    }
    return text?.replace(/(<|&lt;)br\s*\/*(>|&gt;)/g, ' ') || '';
  };
  if (!seo) {
    return {
      title: parseTitle(storyName) || defaultSEOTitle,
      meta: [
        { hid: 'description', name: 'description', content: defaultSEODescription }
      ]
    };
  }
  const title: string = seo.fallback ? parseTitle(storyName) || seo.title || defaultSEOTitle : seo.title || parseTitle(storyName) || defaultSEOTitle;
  const description: string = seo.description || defaultSEODescription;
  const image: string = seo.og_image?.filename || `${defaultURL}og_image.png`;
  return {
    title,
    meta: [
      { hid: 'description', name: 'description', content: description },
      { hid: 'og:description', name: 'og:description', content: description },
      { hid: 'twitter:description', name: 'twitter:description', content: description },
      { hid: 'og:image', name: 'og:image', content: image },
      { hid: 'twitter:image', name: 'twitter:image', content: image }
    ]
  };
};
Vue.prototype.$initStorybridge = (storybridge: any, story: StoryblokStory): void => {
  storybridge(() => {
    // @ts-ignore
    const storyblokInstance = new StoryblokBridge();
    storyblokInstance.on(['input', 'published', 'change'], (event: any) => {
      if (event.action === 'input') {
        if (event.story.id === story.id) {
          story.content = event.story.content;
        }
      } else {
        window.location.reload();
      }
    });
    storyblokInstance.on(['enterEditmode'], (event: any) => {
      const app = window.$nuxt.context.app;
      const language = app.i18n.locale || 'de';
      const version: string = app.context.env.NUXT_APP_STORYBLOK_VERSION || 'draft';
      app.context.$storyapi
        .get(`cdn/stories/${event.storyId}`, {
          language,
          version
        })
        .then((res: StoryblokResponse) => {
          if (res?.data?.story) {
            story.content = res.data.story.content;
          }
        })
        .catch((err: any) => {
          console.log('not working', err);
        });
    });
  });
};
Vue.prototype.$readableDate = (datestring: string, locale: string): string => {
  const months: {[key: string]: string[]} = {
    de: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember'
    ],
    en: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]
  };
  const date = new Date(datestring.replace(' ', 'T'));
  const month = months[locale][date.getMonth()];
  const day = date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate();
  const year = date.getFullYear();
  return `${day}. ${month} ${year}`;
};
Vue.prototype.$minifiedDate = (datestring: string): string => {
  const date = new Date(datestring.replace(' ', 'T'));
  const year = date.getFullYear();
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  return `${day}.${month}.${year}`;
};
